import styles from '../../styles/header/redeemHeader.module.css';
import ding_token from '../../images/v1/home/ding_token.png';
import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getCoins } from "../../ApiCalls/Redeem/api_v1_redeem";
import leftArrow from "../../images/v1/icons/left_arrow_white.png";
// import {useLocation} from 'react-router-dom';
function Header() {
    let userCoinsRef = useRef();
    let navigate = useNavigate();
    const storedAvatar = localStorage.getItem("avatar");
    const [avatar, setAvatar] = useState(storedAvatar !== null ? storedAvatar : ding_token);
    const [bg, setBg] = useState(storedAvatar !== null ? true : false);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
    }, []);
    useEffect(() => {
        getCoins().then(response => {
            userCoinsRef.current.innerHTML = response.data.data.coins;
            // console.log(response.data.data);

        }).catch(error => {
            console.log(error);
        })

    }, [])
    function navigateHome() {
        return navigate("/home/landing");
    }
    return (
        <>
            <header style={{ background: "#191919" }}>

                <div className={`mt-2`} onClick={navigateHome} style={{ cursor: "pointer" }}>
                    <img className={styles.leftArrowImg} src={leftArrow} alt="..." /><p className={`ml-3 text-white`}>Back to videos</p>
                </div>
                <div>
                    <div>
                        <img src={ding_token} alt="" />
                    </div>
                    <div>
                        <p><span ref={userCoinsRef}>0</span><br />coins</p>
                    </div>
                </div>
            </header>
        </>
    )

}
export default React.memo(Header);