import React, { useEffect, useState } from 'react';

const PwaNotification = ({ isPwaInstallShown }) => {
    const [showNotification, setShowNotification] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    let isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };
    if (isMobile.Android()) {
        // setisAndroid(true);
    }
    useEffect(() => {
        const handleBeforeInstallPrompt = (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
            setShowNotification(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleAddPwa = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then(() => {
                setShowNotification(false);
            });
        }
    };

    const removeHandleAddPwa = () => {
        setShowNotification(false);
    }

    return (
        showNotification && (
            <div className={`pwa-notification position-relative ${isPwaInstallShown && isMobile.Android() ? "opacity1block" : " "}`} style={{ userSelect: "none" }}>
                <p onClick={handleAddPwa} style={{ cursor: "pointer", letterSpacing: "1.2px", userSelect: "none" }}>Add <span>DING</span> to <span style={{ color: "blue", cursor: "pointer" }}>Home</span> screen?</p>
                <p style={{ marginLeft: "25px", position: "absolute", right: "27px", marginLeft: "25px", position: "absolute", right: "0px", width: "70px", height: "30px", lineHeight: "30px", cursor: "pointer", userSelect: "none" }} onClick={removeHandleAddPwa}>X</p>
            </div>
        )
    );
};

export default PwaNotification;
