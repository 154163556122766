import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import styles from '../../styles/redeem/redeemBody.module.css';
import pubg from '../../images/v1/redeem/pubg.png';
import fortnite from '../../images/v1/redeem/fortnite.png';
import roblox from '../../images/v1/redeem/roblox.png';
import valorant from '../../images/v1/redeem/valorant.png';
import callofduty from '../../images/v1/redeem/cod.png';
import { getItemPrice, getRedeemItem } from "../../ApiCalls/Redeem/api_v1_redeem";
import RedeemForm from "./RedeemForm";
import SfxMainAudio from "../AutoPlayAudio/SfxMainAudio";
import SubscribeConsentForm from './SubscribeConsentForm';
import Footer from "../footer/Footer";
function RedeemBody() {
    const welcomDing = useRef(null);
    const welcomeDv = localStorage.getItem("dv");
    const redeemItemsLoading = useRef(false);
    const [redeemPricingFromApi, setredeemPricingFromApi] = useState([]);
    let navigate = useNavigate();
    const [showWelcomeDing, setShowWelcomeDing] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [insufficentMsg, setInsufficentMsg] = useState(false);
    const [KoMsg, setKoMsg] = useState(false);
    const [KoText, setKoText] = useState("");

    const [showForm, setShowForm] = useState({
        formConsentShow: false,
        formShow: false,
        resp: "",
        item: "",
        key: "",
        name: "",
        tel: "",
        uuid: "",

    });
    useEffect(() => {

        async function redeemItems() {
            try {
                const res = await getItemPrice();
                setredeemPricingFromApi(res.data.data);
                // Process the response as needed
            } catch (error) {
                // Handle errors
                setDisabled(true);
            } finally {
                // Update the loading status or perform any cleanup
                redeemItemsLoading.current = true;
            }
        }

        if (!redeemItemsLoading.current) {
            redeemItems();
        }
    }, [redeemItemsLoading.current]);
    useEffect(() => {

        const dv = localStorage.getItem("dv");
        if (!dv || dv === "1") {
            setShowWelcomeDing(true);
            welcomDing.current.classList.remove(styles.hidden);
            const timeout = setTimeout(() => {
                localStorage.setItem("dv", "2");
                navigate("/home/landing");
            }, 5000);

            return () => clearTimeout(timeout); // Cleanup function to clear the timeout
        }
    }, []);
    useLayoutEffect(() => {
        document.body.style.backgroundColor = "#191919";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#191919");
        document.title = "Redeem | Ding";
    }, []);
    // let displayNone = useRef();
    const handleClick = (e) => {
        let value = e.target.getAttribute('value');
        e.target.innerHTML = "Please Wait";
        e.target.style.backgroundColor = "rgb(74, 74, 74)";
        setDisabled(true);
        let itemData = {
            "item": value
        }
        getRedeemItem(itemData).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            // && response.data.data === "free trial"
            if (response.data.message === "KoF") {
                setShowForm({ ...showForm, formConsentShow: true });
                return;
            }
            if (response.data.message === "KoU") {
                setShowForm({ ...showForm, formConsentShow: true });
                return;
            }
            if (response.data.message === "Ko" && response.status === 200) {
                setKoMsg(true);
                setKoText(response.data.data);
                setTimeout(() => {   // ***
                    setMessage();  // *** If you want to clear the error message as well
                }, 3000);
                // setTimeout(
                //     () => console.log(insufficentMsg),e.target.style.backgroundColor= "rgb(61, 60, 58)", e.target.innerHTML="redeem",setInsufficentMsg(false),setDisabled(false),
                //     50000
                //   );

            }
            else if (response.data.message === "ok" && response.status === 200) {
                let resp = response.data.data;
                let item = resp.item;
                let key = resp.key;
                let name = resp.name;
                let tel = resp.tel;
                let uuid = resp.uuid;

                setShowForm({ ...showForm, formShow: true, item: item, key: key, name: name, tel: tel, uuid: uuid });

                // console.log(response.data.data);
            }


        }).catch(error => {
            console.log(error);
        });
        function setMessage() {
            // console.log(insufficentMsg); 
            e.target.style.backgroundColor = "#252525"; e.target.innerHTML = "Redeem"; setInsufficentMsg(false); setDisabled(false);
        }
    }
    function navigateHome() {
        return navigate("/home/landing");
    }
    return (
        <>
            <SfxMainAudio />
            {(showForm.formShow) ? <RedeemForm showForm={showForm} /> :
                (showForm.formConsentShow) ? <SubscribeConsentForm /> :
                    <main className={styles.main} id="main">
                        <section className={`${styles.insufficentCoins} ` + (insufficentMsg ? `${styles.show}` : `${styles.hidden}`)}>
                            <p>Insufficent Coins</p>
                        </section>
                        <section className={`${styles.insufficentCoins} ` + (KoMsg ? `${styles.show}` : `${styles.hidden}`)}>
                            <p>{KoText}</p>
                        </section>
                        <section className={styles.redeemCard}>
                            <img className={styles.cardImg} src={pubg} alt="..." />
                            <div>
                                <h1 className='text-theme fs-22'>Battlegrounds</h1>
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.pubg ? redeemPricingFromApi.pubg : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="1" className={`text-theme ${styles.btn}`} onClick={handleClick} disabled={isDisabled}>Redeem</button>
                            </div>
                        </section>
                        <section className={styles.redeemCard}>
                            <img className={styles.cardImg} src={fortnite} alt="..." />
                            <div>
                                <h1 className='text-theme fs-22'>Fortnite</h1>
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.fortnite ? redeemPricingFromApi.fortnite : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="2" className={`text-theme ${styles.btn}`} onClick={handleClick} disabled={isDisabled}>Redeem</button>
                            </div>
                        </section>
                        <section className={styles.redeemCard}>
                            <img className={styles.cardImg} src={valorant} alt="..." />
                            <div>
                                <h1 className='text-theme fs-22'>Valorant</h1>
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.valorant ? redeemPricingFromApi.valorant : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="3" className={`text-theme ${styles.btn}`} onClick={handleClick} disabled={isDisabled}>Redeem</button>
                            </div>
                        </section>
                        <section className={styles.redeemCard}>
                            <img className={styles.cardImg} src={callofduty} alt="..." />
                            <div>
                                <h1 className='text-theme fs-22'>Call of Duty</h1>
                                <p className={styles.text} dangerouslySetInnerHTML={{ __html: redeemPricingFromApi.callofduty ? redeemPricingFromApi.callofduty : "Loading..." }}></p>
                            </div>
                            <div className={styles.rightSideBtn}>
                                <button value="4" className={`text-theme ${styles.btn}`} onClick={handleClick} disabled={isDisabled}>Redeem</button>
                            </div>
                        </section>
                        <Footer />
                    </main>

            }
        </>
    )
}
export default React.memo(RedeemBody);