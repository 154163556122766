import React, { useEffect, useRef, useState, } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import styles from '../../styles/savedVideos/SavedVideos.module.css';
import { SharedVideos } from "../../ApiCalls/SavedVideos/api_v1_saved_videos";
import SidebarBottom from "../home/SidebarBottom";
import LogoAnimation from "../Animations/LogoAnimation";
import play from "../../images/v1/icons/play.svg";
// import SearchPopup from '../home/SearchPopup';
import LinkCopied from '../home/LinkCopied';
import Share from '../home/Share';
import SidebarBottomSavedVideos from '../savedVideos/SidebarBottomSavedVideos';
import BufferingAnimation from '../Animations/BufferingAnimation';

function WatchSharedVideo() {
    let navigate = useNavigate();
    const location = useLocation();
    let encodedVideoId = (new URLSearchParams(window.location.search)).get("v");
    let decodedVideoId = atob((new URLSearchParams(window.location.search)).get("v"));
    const [copied, setCopied] = useState(0);
    const showShare = useRef(false);
    const showSearch = useRef(false);
    const [isBuffering, setIsBuffering] = useState(false);
    const [videoInfo, setVideoInfo] = useState({
        isloading: true,
        src: "",
        isPlaying: false,
        vid: decodedVideoId,
    });
    const videoRef = useRef(null);
    useEffect(() => {
        document.title = "Watch Shared Video | Ding";
    }, [])

    useEffect(() => {
        if (videoInfo.isloading === true) {
            let videoData = {
                videoId: encodedVideoId
            }
            SharedVideos(videoData).then(response => {
                // console.log(response)
                let data = response.data.data;
                let vSrc = data.video;
                let thumbnail = data.thumbnail;
                let title = data.title;
                // document.querySelector('meta[name="description"]').setAttribute("content", "Watch exciting Video at Ding now and win exciting rewards");
                // document.title = title + " | Ding";
                // document.querySelector('meta[property="og:title"]').setAttribute("content", title);
                // document.querySelector('meta[property="og:description"]').setAttribute("content", "Watch exciting Video at Ding now and win exciting rewards");
                // document.querySelector('meta[property="og:image"]').setAttribute("content", thumbnail);
                // document.querySelector('meta[property="og:video"]').setAttribute("content", vSrc);
                // document.querySelector('meta[property="og:video:type"]').setAttribute("content", "video/mp4");
                // Create the og:video meta element
                // const videoMeta = document.createElement('meta');
                // videoMeta.setAttribute('property', 'og:video');
                // videoMeta.setAttribute('content', vSrc);

                // Create the og:video:type meta element
                // const videoTypeMeta = document.createElement('meta');
                // videoTypeMeta.setAttribute('property', 'og:video:type');
                // videoTypeMeta.setAttribute('content', 'video/mp4');

                // const siteUrlMeta = document.createElement('url');
                // siteUrlMeta.setAttribute('property', 'og:url');
                // siteUrlMeta.setAttribute('content', window.location.pathname + window.location.search);


                // Append the created meta elements to the head
                // document.head.appendChild(videoMeta);
                // document.head.appendChild(videoTypeMeta);
                // document.head.appendChild(siteUrlMeta);
                // // Function to dynamically set OG tags
                // const ogTitle = document.createElement('meta');
                // ogTitle.setAttribute('property', 'og:title');
                // ogTitle.setAttribute('content', title);
                // document.head.appendChild(ogTitle);

                // const ogDescription = document.createElement('meta');
                // ogDescription.setAttribute('property', 'og:description');
                // ogDescription.setAttribute('content', "Watch Now");
                // document.head.appendChild(ogDescription);

                // const ogImage = document.createElement('meta');
                // ogImage.setAttribute('property', 'og:image');
                // ogImage.setAttribute('content', thumbnail);
                // document.head.appendChild(ogImage);

                // const ogVideo = document.createElement('meta');
                // ogVideo.setAttribute('property', 'og:video');
                // ogVideo.setAttribute('content', vSrc);
                // document.head.appendChild(ogVideo);

                // const ogVideoType = document.createElement('meta');
                // ogVideoType.setAttribute('property', 'og:video:type');
                // ogVideoType.setAttribute('content', 'video/mp4');
                // document.head.appendChild(ogVideoType);
                setVideoInfo({ ...videoInfo, isloading: false, src: vSrc, vid: decodedVideoId })
                setTimeout(() => {

                    xContextMenu();
                }, 1500);

            }).catch(error => {
                console.log(error);
            });
        }
        return;
    }, [videoInfo.isloading === true])

    function xContextMenu() {
        const videoElement = document.querySelector('video');

        videoElement.addEventListener('contextmenu', event => {
            event.preventDefault();
        });
    }

    const playPause = (e) => {

        hidePopups();
        if (videoInfo.isPlaying === false) {
            // e.target.play();
            if (videoRef.current) {
                videoRef.current.play(); // Play the video
            }
            setVideoInfo({ ...videoInfo, isPlaying: true })
        }
        else {
            // e.target.pause();
            if (videoRef.current) {
                videoRef.current.pause(); // Play the video
            }
            setVideoInfo({ ...videoInfo, isPlaying: false })
        }

    }
    function hidePopups() {
        showShare.current = false;
        showSearch.current = false;
    }
    function likeDislikeHandler(like) {
        setVideoInfo({ ...videoInfo, like: like })
    }
    function hideLogoAnimation(hide) {

        setVideoInfo({ ...videoInfo, isloading: false })

    }
    const myCallback = () => {
        let path = "/login";
        navigate(path);
    };

    function showShareSection() {
        showShare.current = !showShare.current;
    }
    function showSearchSection() {
        videoRef.current.pause();
        showSearch.current = !showSearch.current;
    }
    function copyToClipboardHandler() {
        setCopied(1);
        setTimeout(
            () => setCopied(0),
            600
        );
    }
    function handleBuffering() {
        setIsBuffering(true);
    }
    function handleBufferingComplete() {
        setIsBuffering(false);
    }

    return (
        <>
            <div className={styles.watchVideoContainer}>
                {videoInfo.isloading ? <LogoAnimation hideLogoAnimation={hideLogoAnimation} /> :
                    <div className={styles.videoCard_player_shared} style={{ paddingBottom: "0" }}>
                        {!videoInfo.isPlaying ?
                            <div className={styles.playBtnDiv}>
                                <img src={play} id={styles.playBtnSvg} onClick={playPause} alt="" />
                            </div>
                            :
                            videoInfo.isPlaying && isBuffering ? <BufferingAnimation />
                                :
                                null
                        }
                        <video src={videoInfo.src} type="video/mp4; codecs=avc1.640028, mp4a.40.2" onWaiting={handleBuffering} onPlaying={handleBufferingComplete} className={styles.watchSavedVideoContent} onEnded={() => myCallback()} onClick={playPause} preload="metadata" ref={videoRef} controlsList='nodownload' playsInline={true} webkit-playsinline="true">
                            {/* <source /> */}
                        </video>
                        {/* <SidebarBottom vid={videoInfo.vid} like={videoInfo.like} likeDislikeHandler={likeDislikeHandler} /> */}
                        <SidebarBottomSavedVideos vid={videoInfo.vid} like={videoInfo.like} likeDislikeHandler={likeDislikeHandler} showShareSection={showShareSection} showSearchSection={showSearchSection} />
                    </div>
                }
                {copied ? <LinkCopied /> : null}
                <div className={(showShare.current) ? `${styles.slideInUp}` : `${styles.slideOutDown}`} onContextMenu={(e) => e.preventDefault()}>
                    <Share vid={videoInfo.vid} copyToClipboardHandler={copyToClipboardHandler} />
                </div>
            </div>
        </>
    )
}

export default React.memo(WatchSharedVideo);