import React, { useEffect, useState } from 'react';
import styles from '../../styles/footer/footer.module.css';
import { NavLink, useLocation } from 'react-router-dom';
// import home from '../../images/v1/home/home.svg';
// import bookmark from '../../images/v1/home/bookmark.svg';
// import wallet from '../../images/v1/home/wallet.svg';
// import profile from '../../images/v1/home/profile.png';
function Footer() {
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/home/landing') ? setIsActive(true) : setIsActive(false);

    }, [location.pathname])

    return (
        <>
            <div className={styles.footerContainer} onContextMenu={(e) => e.preventDefault()}>
                <ul style={{ display: "flex", flexBasis: "100%", marginLeft: "0", paddingLeft: "0" }}>
                    <li className={styles.footerBtn}>
                        <NavLink to='/home/landing' className={`${styles.footerLink} ${isActive ? "active" : ''}`}>
                            <svg fill="#000000" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px"><path d="M 23.951172 4 A 1.50015 1.50015 0 0 0 23.072266 4.3222656 L 8.859375 15.519531 C 7.0554772 16.941163 6 19.113506 6 21.410156 L 6 40.5 C 6 41.863594 7.1364058 43 8.5 43 L 18.5 43 C 19.863594 43 21 41.863594 21 40.5 L 21 30.5 C 21 30.204955 21.204955 30 21.5 30 L 26.5 30 C 26.795045 30 27 30.204955 27 30.5 L 27 40.5 C 27 41.863594 28.136406 43 29.5 43 L 39.5 43 C 40.863594 43 42 41.863594 42 40.5 L 42 21.410156 C 42 19.113506 40.944523 16.941163 39.140625 15.519531 L 24.927734 4.3222656 A 1.50015 1.50015 0 0 0 23.951172 4 z M 24 7.4101562 L 37.285156 17.876953 C 38.369258 18.731322 39 20.030807 39 21.410156 L 39 40 L 30 40 L 30 30.5 C 30 28.585045 28.414955 27 26.5 27 L 21.5 27 C 19.585045 27 18 28.585045 18 30.5 L 18 40 L 9 40 L 9 21.410156 C 9 20.030807 9.6307412 18.731322 10.714844 17.876953 L 24 7.4101562 z" /></svg>
                            <p className={styles.linkName}>Home</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to="/saved" className={styles.footerLink}>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.82347 9.12312C3.22547 13.4851 8.76447 17.0121 10.2365 17.8851C11.7135 17.0031 17.2925 13.4371 18.6495 9.12712C19.5405 6.34112 18.7135 2.81212 15.4275 1.75312C13.8355 1.24212 11.9785 1.55312 10.6965 2.54512C10.4285 2.75112 10.0565 2.75512 9.78647 2.55112C8.42847 1.53012 6.65447 1.23112 5.03747 1.75312C1.75647 2.81112 0.932468 6.34012 1.82347 9.12312ZM10.2375 19.5011C10.1135 19.5011 9.99047 19.4711 9.87847 19.4101C9.56547 19.2391 2.19247 15.1751 0.395468 9.58112C0.394468 9.58112 0.394468 9.58012 0.394468 9.58012C-0.733532 6.05812 0.522469 1.63212 4.57747 0.325118C6.48147 -0.290882 8.55647 -0.019882 10.2345 1.03912C11.8605 0.0111181 14.0205 -0.272882 15.8865 0.325118C19.9455 1.63412 21.2055 6.05912 20.0785 9.58012C18.3395 15.1101 10.9125 19.2351 10.5975 19.4081C10.4855 19.4701 10.3615 19.5011 10.2375 19.5011Z" fill="white" />
                            </svg>
                            <p className={styles.linkName}>Saved</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to="/redeem" className={styles.footerLink}>
                            <svg width="44" height="44" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlinkHref="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 64 64" enableBackground="new 0 0 64 64" xmlSpace="preserve"><g id="Wallet"><path d="M57.7901001,7H6.2100005c-3.4238999,0-6.2099996,2.7901001-6.2099996,6.2196999v3.9922009v29.5683994v4.0078011C0.0000008,54.2128983,2.7871008,57,6.2119007,57h47.5762024c2.6661987,0,4.9391975-1.6922989,5.8182983-4.0564995C62.3984985,52.6615982,64,50.4676018,64,46.7803001V13.2196999C64,9.7901001,61.2138977,7,57.7901001,7z M53.7881012,55H6.2119007c-2.3222001,0-4.2118998-1.8895988-4.2118998-4.2118988v-4.0078011V17.2119007C2.0000007,14.8896999,3.8897007,13,6.2119007,13h47.5762024C56.1104012,13,58,14.8896999,58,17.2119007V24H45.9482994C41.0136986,24,37,28.1231003,37,33.1913986v1.6182022C37,39.8769989,41.0136986,44,45.9482994,44H58v6.7881012C58,53.1104012,56.1104012,55,53.7881012,55z M58,42H45.9482994C42.1171989,42,39,38.7743988,39,34.8096008v-1.6182022C39,29.2255993,42.1171989,26,45.9482994,26H58V42z M62,46.7803001c0,2.9328995-1.0900993,3.8256989-2.0046005,4.0984993C59.9958992,50.8483009,60,50.8186989,60,50.7881012V17.2119007C60,13.7870998,57.2128983,11,53.7881012,11H6.2119007c-1.5956998,0-3.048295,0.6101999-4.1497002,1.6029997C2.3630006,10.5697002,4.0994005,9,6.2100005,9h51.580101C60.1112976,9,62,10.8926001,62,13.2196999V46.7803001z" /><path d="M42,34c0,2.2061005,1.7939987,4,4,4c2.2061005,0,4-1.7938995,4-4c0-2.2059994-1.7938995-4-4-4C43.7939987,30,42,31.7940006,42,34z M46,32c1.1026039,0,2,0.8974991,2,2c0,1.1026001-0.8973961,2-2,2c-1.1025009,0-2-0.8973999-2-2C44,32.8974991,44.8974991,32,46,32z" /></g></svg>
                            <p className={styles.linkName}>Redeem</p>
                        </NavLink>
                    </li>
                    <li className={styles.footerBtn}>
                        <NavLink to="/profile" className={styles.footerLink}>
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 9C10.1935 9 11.3381 8.52589 12.182 7.68198C13.0259 6.83807 13.5 5.69347 13.5 4.5C13.5 3.30653 13.0259 2.16193 12.182 1.31802C11.3381 0.474106 10.1935 0 9 0C7.80653 0 6.66193 0.474106 5.81802 1.31802C4.97411 2.16193 4.5 3.30653 4.5 4.5C4.5 5.69347 4.97411 6.83807 5.81802 7.68198C6.66193 8.52589 7.80653 9 9 9ZM12 4.5C12 5.29565 11.6839 6.05871 11.1213 6.62132C10.5587 7.18393 9.79565 7.5 9 7.5C8.20435 7.5 7.44129 7.18393 6.87868 6.62132C6.31607 6.05871 6 5.29565 6 4.5C6 3.70435 6.31607 2.94129 6.87868 2.37868C7.44129 1.81607 8.20435 1.5 9 1.5C9.79565 1.5 10.5587 1.81607 11.1213 2.37868C11.6839 2.94129 12 3.70435 12 4.5ZM18 16.5C18 18 16.5 18 16.5 18H1.5C1.5 18 0 18 0 16.5C0 15 1.5 10.5 9 10.5C16.5 10.5 18 15 18 16.5ZM16.5 16.494C16.4985 16.125 16.269 15.015 15.252 13.998C14.274 13.02 12.4335 12 9 12C5.5665 12 3.726 13.02 2.748 13.998C1.731 15.015 1.503 16.125 1.5 16.494H16.5Z" fill="white" />
                            </svg>
                            <p className={styles.linkName}>Profile</p>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default React.memo(Footer);