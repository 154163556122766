import axiosClient from "../axiosClient";

export function getAuth(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/authenticate', JSON.stringify(data));
}

export function getDecryptMsisdn(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/dt/decrypt/msisdn', JSON.stringify(data))
}
export function getJtToken(data) {
    return axiosClient.post('/jt/gen', JSON.stringify(data))
}
export function getGuuid(data) {
    return axiosClient.post('/guuid', JSON.stringify(data));
}

export function getGuuidToken(data) {
    return axiosClient.post('/guuid/token', JSON.stringify(data));
}

export function storeToken(token) {
    localStorage.setItem('token', token);
}

export function removeTokens() {
    localStorage.removeItem('token');
    localStorage.removeItem('profileData');
}