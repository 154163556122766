import axiosClient from "../axiosClient";

export function getHeaders(data) {
    return axiosClient.get("/fetchHeader?user=" + data.user + "&h=" + data.h + "&tc=" + data.tc + "&clickid=" + data.clickId + "&cpid=" + data.cpId);
}

export function getVideos() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('/getVideos');
}

export function likeDislike(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/like', JSON.stringify(data));
}

export function questionRetrive(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/trivia', JSON.stringify(data));
}

export function questionSubmit(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/trivia/submit', JSON.stringify(data));
}

export function videoWatched(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/watchedv', JSON.stringify(data));
}

export function getVerifyUser() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get('/verify-user');
}

export function searchVideo(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/search', JSON.stringify(data));
}

export function getUidVideo(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/getVid', JSON.stringify(data));
}

export function apiCallToFetchSuggestedTopics(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post('/suggested-search', JSON.stringify(data));
}
// export function otpValidate(data,bearerTokenHeader){
//     axiosClient.defaults.headers.common['Authorization'] = bearerTokenHeader;
//     return axiosClient.post('/validate', JSON.stringify(data));
// }