import React, { useEffect, useState, useRef } from 'react';
import logoAnim from "../../animations/ding_logo_2.mov";
import styles from "../../styles/animations_styles/logo_animation.module.css";
import Loader from './Loader';
const LogoAnimation = ({ hideLogoAnimation }) => {
  const [showLoader, setShowLoader] = useState(1);
  const refVideo = useRef(null);
  useEffect(() => {
    var a = document.getElementById("vd");
    autoPlay(a);

    return () => {
      // second
      //   refVideo.target.current.stop();
      a.pause();
      // console.log("cleaned up");
    }
  }, []);
  const touchstart = (a) => {
    a.volume = 0.07;
    a.play();
    if (a.paused) {
      a.muted = true;
      a.play();
      if (a.paused) {
        setShowLoader(1);
        setTimeout(
          () => handleCallback(),
          1000
        );
        // console.log("Loader showing");

      }
      else {
        setShowLoader(0);
        // console.log("Loader not showing sedc");
      }

      // setShowLoader(1);
      // handleCallback();
    }
    else {
      // console.log("Loader  not showing");
      setShowLoader(0)
    }

  }

  const autoPlay = (key) => {
    let autoPlayAllowed = true;
    let v = document.getElementById('vd');
    const promise = v.play();
    if (promise instanceof Promise) {
      promise.catch(function (error) {
        console.log(error.message);
        // Check if it is the right error
        if (error.name === 'NotAllowedError') {
          autoPlayAllowed = false;
          refVideo.current.defaultMuted = true;
          refVideo.current.muted = true;
        }
      }).then(function () {
        if (autoPlayAllowed) {
          // Autoplay is allowed - continue with initialization
          // console.log('autoplay allowed')
          let vidElem = document.getElementById("vd");
          vidElem.volume = 0.07;
          vidElem.play();
          setShowLoader(0);
        } else {
          // console.log('autoplay NOT allowed')
          let vidElem = document.getElementById("vd");
          vidElem.muted = true;
          vidElem.muted = true;
          refVideo.current.defaultMuted = true;
          refVideo.current.muted = true;
          vidElem.play();
          setShowLoader(0);
          // Autoplay is not allowed - wait for the user to trigger the play button manually

        }
      });

    } else {
      // console.log('autoplay unknown')
      let vidElem = document.getElementById("vd");
      vidElem.muted = true;
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
      vidElem.play();
      setShowLoader(0);
      // Unknown if allowed
      // Note: you could fallback to simple event listeners in this case

    }
  }
  const handleCallback = (e) => {
    // console.log("calback called")
    const dv = localStorage.getItem("dv")
    if (dv == null) {
      // console.log("localstorage null")
      hideLogoAnimation(0); //show animation
    }
    else {
      // console.log("localstorage not null")
      hideLogoAnimation(0) //hide animation
    }
  }
  return (
    <>
      {showLoader == 1 ? <Loader /> : null}
      <video src={logoAnim} type="video/mp4" className={styles.homeVideoContent} id="vd" onEnded={handleCallback} preload="auto" controlsList='nodownload' playsInline={true} webkit-playsinline="true" ref={refVideo} muted={true}></video>
      {/* <video className={styles.homeVideoContent} autoPlay muted onEnded={()=>handleCallback()}  preload="auto"   controlsList='nodownload'>
            <source src={logoAnim} type="video/mp4"/>
        </video> */}

    </>
  )
}


export default React.memo(LogoAnimation);
