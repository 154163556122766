import React, { useEffect, useRef, useMemo } from 'react';
import mainBgAudio from "../../audio/ding_sound_track.aac";
const SrfMainAudio = () => {
    const triviaTrackPlaying = useRef(false);
    const track1 = useMemo(() => new Audio(mainBgAudio), []);
    useEffect(() => {

        // var track1 = useMemo(() => new Audio(mainBgAudio), []);
        // new Audio(mainBgAudio)
        touchstart(track1);
        return () => {
            track1.pause();
            localStorage.setItem('time', track1.currentTime);
            //   console.log("cleaned up");
        }
    }, [])

    const touchstart = (track1) => {
        let time = localStorage.getItem("time");
        if (triviaTrackPlaying.current == false) {
            triviaTrackPlaying.current = true;
            track1.loop = true;
            track1.volume = 0.07;
            track1.currentTime = time;
            track1.play();
        }
    };
    return (
        <>
        </>
    )
}


export default React.memo(SrfMainAudio);
